<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="dept_content">
            <el-card shadow="always">
              <div class="dept_top_content">
                <span class="countDept"
                  >{{ pageData.deptList.length
                  }}{{ $t("dept.span_countdept") }}</span
                >
                <el-button size="small" type="primary" @click="addDept">{{
                  $t("dept.btn_adddept")
                }}</el-button>
                <el-dialog
                  width="20%"
                  :title="$t('dept.title_add_dept')"
                  v-model="pageData.deptAdderVisible"
                  ref="deptAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.newDept"
                    ref="newDeptForm"
                    class="newDeptForm"
                    size="mini"
                  >
                    <el-form-item :label="$t('dept.label_dept')">
                      <el-input
                        v-model="formData.newDept.name"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('dept.label_dept_en')">
                      <el-input
                        v-model="formData.newDept.name_en"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addDeptBtn"
                        type="success"
                        size="medium"
                        @click="doAddDept(formData.newDept)"
                        :loading="pageData.doAddDeptLoading"
                        >{{ $t("dept.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div class="dept_body_content">
                <el-table
                  stripe
                  border
                  ref="deptTable"
                  :header-cell-style="{
                    textAlign: 'center',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'center' }"
                  :data="pageData.deptList"
                >
                  <el-table-column :label="$t('dept.label_dept')">
                    <template #default="scope">
                      <span v-if="scope.row.id === pageData.currentClickId">
                        <el-input
                          size="mini"
                          v-model="scope.row.name"
                          class="input_items"
                          maxlength="70"
                          show-word-limit
                        ></el-input>
                      </span>
                      <span v-else>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('dept.label_dept_en')">
                    <template #default="scope">
                      <span v-if="scope.row.id === pageData.currentClickId">
                        <el-input
                          size="mini"
                          v-model="scope.row.name_en"
                          class="input_items"
                          maxlength="70"
                          show-word-limit
                        ></el-input>
                      </span>
                      <span v-else>{{ scope.row.name_en }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('dept.label_options')">
                    <template #default="scope">
                      <el-button
                        circle
                        class="el-icon-edit"
                        type="primary"
                        size="small"
                        @click="modifyDept(scope.row)"
                        v-if="scope.row.id !== pageData.currentClickId"
                      ></el-button>
                      <el-button
                        circle
                        class="el-icon-check"
                        type="success"
                        size="small"
                        @click="confirmModifyDept(scope.row)"
                        v-else
                      ></el-button>

                      <el-popconfirm
                        :title="$t('text.message_delete_confirm')"
                        @confirm="deleteDept(scope.row)"
                      >
                        <template #reference>
                          <el-button
                            circle
                            class="el-icon-delete"
                            type="danger"
                            size="small"
                          ></el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        deptList: [],
        deptAdderVisible: false,
        doAddDeptLoading: false,
        oldDept: ''
      },
      formData: {
        newDept: {
          name: '',
          name_en: ''
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      this.getDept();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    getDept() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryDept',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.deptList = response.data.data;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('dept.notify_deptnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    deleteDept(dept) {
      dept.reviser = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/deleteDept',
        data: dept
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_deleted"),
              type: 'success'
            });

            for (let index in this.pageData.deptList) {
              if (this.pageData.deptList[index].id === dept.id) {
                this.pageData.deptList.splice(index, 1);
              }
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    addDept() {
      this.pageData.deptAdderVisible = true;
    },
    doAddDept(dept) {
      this.pageData.doAddDeptLoading = true;

      for (let index in dept) {
        if (utilsjs.strIsEmpty(dept[index])) {
          this.pageData.doAddDeptLoading = false;
          this.$notify.error({
            title: this.$t("common.notity_title_message"),
            message: this.$t('dept.notify_incompletely')
          });
          return;
        } else {
          continue;
        }
      }

      //set values
      dept.creator = this.pageData.username;
      dept.reviser = this.pageData.username;

      //post
      this.postToAddDept(dept);
    },
    postToAddDept(dept) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createDept',
        data: dept
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doAddDeptLoading = false;
            this.pageData.deptAdderVisible = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddDeptLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddDeptLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddDeptLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    modifyDept(dept) {
      this.pageData.currentClickId = dept.id;
      this.pageData.oldDept = '';
      this.pageData.oldDept = JSON.parse(JSON.stringify(dept));
    },
    confirmModifyDept(dept) {
      if (dept.name !== this.pageData.oldDept.name || dept.name_en !== this.pageData.oldDept.name_en) {
        if (utilsjs.strIsEmpty(dept.name) || utilsjs.strIsEmpty(dept.name_en)) {
          //incomplete
          return;
        } else {
          //save changes
          dept.reviser = this.pageData.username;

          //post
          this.$axios({
            method: 'post',
            url: this.$api + '/api/evaluate/reviseDept',
            data: dept
          }).then((response) => {
            if (response.status == 200) {
              if (response.data.code == '200') {
                this.$ElMessage.success({
                  message: this.$t("text.message_saved"),
                  type: 'success'
                });

                this.pageData.currentClickId = null;
                this.pageData.oldDept = null;
                return;
              } else {
                //code = 700 and more
                this.$notify.error({
                  title: this.$t("notify.title_server"),
                  message: this.$t('notify.content_refused')
                });
                return;
              }
            } else {
              this.$notify.error({
                title: this.$t('notify.title_server'),
                message: this.$t('notify.content_server') + response.status
              });
              return;
            }
          }).catch((error) => {
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          });
        }
      } else {
        //no change
        this.pageData.currentClickId = null;
        this.pageData.oldDept = null;

        this.$ElMessage.warning({
          message: this.$t("text.message_nochange"),
          type: 'success'
        });
        return;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dept_content {
  margin-top: 10px;
  padding: 20px;
}
.countDept {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  color: #f56c6c;
}
.dept_top_content .el-button {
  float: right;
  margin-bottom: 20px;
}
.addDeptBtn {
  margin-top: -30px;
  float: right;
}
</style>